// @ts-nocheck
// import type { IdebounceOptions } from '@/@types';

// module.exports = (func: void, delay: number = 300): void {
// 	let timer = null;
// 	return function () {
// 		let context: any = this; // 注意 this 指向
// 		let args: any = arguments; // arguments中存着e

// 		if (timer) clearTimeout(timer);

// 		timer = setTimeout(() => {
// 			func.apply(this, args);
// 		}, delay);
// 	}
// };

// /**
//  * 防抖函数--在事件被触发n秒后再执行回调，如果在这n秒内又被触发，则重新计时
//  */
//  export const debounce = function (fun: any, delay: number) {
// 	return function (args: any) {
// 			const that = this;
// 			clearTimeout(fun.id);
// 			fun.id = setTimeout(function () {
// 				fun.call(that, args);
// 			}, delay);
// 	};
// };

interface IDebounceOption {
	leading?: boolean,
	trailing?: boolean,
	context: any
}

const DEFAULT_OPTIONS: IDebounceOption = {
	leading: true,
	trailing: true,
	context: null
};

// 默认延迟时间（毫秒）
const DEFAULT_TIME = 300;

/** 防抖函数
 *
 * @param func 要附加防抖保护的函数
 * @param time 延迟时间
 * @param options 配置
 * @returns
 */
export const debounce = (func: any, time?: number, opt?: IDebounceOption) => {
  const _timeVal_ = time || DEFAULT_TIME;
  const options = opt || DEFAULT_OPTIONS;
	let timmer: any = null;
	const _debounce = function(...args: any[]) {
		if (timmer) {
			clearTimeout(timmer);
		}
		if (options?.leading && !timmer) {
			timmer = setTimeout(() => {}, _timeVal_);
			func.apply(options?.context, args);
		} else if (options?.trailing) {
			timmer = setTimeout(() => {
				func.apply(options?.context, args);
				timmer = null;
			}, _timeVal_);
		}
	};

	_debounce.cancel = function() {
		clearTimeout(timmer as number);
		timmer = null;
	};
	return _debounce;
};
